import * as React from "react"
import Layout from "../components/layout"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const News = ({ data }) => {

  const articles = data.articles.nodes;

  const truncate = (text: string, maxChars: number = 100, suffix: string = "…") => {
    if (text.length > maxChars) {
      return text.substring(0, maxChars - suffix.length).trim() + suffix;
    } else {
      return text;
    }
  };

  return (
    <Layout>
      <SEO title='News'/>
      <article>
        <h1 className='mb-5'>News and Articles</h1>

        <Row xs={1} sm={2} md={3}>
          {articles.map(article => {


            // Build out React components to get an array of the article text.
            const innerStrings = [];
            if (article.subtitle) innerStrings.push(`${article.subtitle}.`);
            renderRichText(article.body, {
              renderText: text => innerStrings.push(text)
            });

            const excerpt = innerStrings.join(' ').trim();


            return <Col className='mb-5' key={article.id}>
              <Card as='article' className='h-100 border-light bg-transparent article-card'>
                <Card.Body className=' d-flex flex-column'>
                  <Card.Title>
                    <header className='mb-3'>
                      <Link className='text-decoration-none' to={article.slug}>
                        <h2 className='h4 mb-1'>
                          {article.title}
                        </h2>
                      </Link>
                      <small>
                        <time dateTime={article.date}>{article.niceDate}</time>
                        { article.author && <>
                          <br />
                          <Link to={`/team/${article.author.slug}`}>{article.author.name}</Link>
                        </>}
                      </small>
                    </header>
                  </Card.Title>


                  <Card.Text className='flex-grow-1'>{truncate(excerpt, 150)}</Card.Text>

                  <div className='text-right'>
                    <Link to={article.slug}>
                      Read More&hellip;
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          })}
        </Row>
      </article>
    </Layout>
  )
}

export default News;

export const query = graphql`
  {
    articles: allContentfulNewsArticle(sort: {fields: date, order: DESC}) {
      nodes {
        id
        slug
        updatedAt
        title
        subtitle
        tags
        date
        niceDate: date(formatString: "dddd Do MMMM YYYY")
        author {
          name
          slug
        }
        body {
          raw
        }
      }
    }
  }
`
